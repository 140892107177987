<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="modal fade" id="modal_form_rutasRegalias_export" style="background-color: #00000082">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Ruta Regalias</h4>
            <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close" id="close-modal" ref="closeModal1">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="tab-Columnas" data-toggle="tab" href="#Columnas">Columnas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="tab-Filtros" data-toggle="tab" href="#Filtros">Filtros</a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" id="nombre1" v-model="form.nombre" class="custom-control-input" />
                          <label class="custom-control-label" for="nombre1">Nombre</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" id="cod_dane1" v-model="form.codigo" class="custom-control-input" />
                          <label class="custom-control-label" for="cod_dane1">Codigo</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" id="origen1" v-model="form.origen_id" class="custom-control-input" />
                          <label class="custom-control-label" for="origen1">Sitio Origen</label>
                        </div>
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" id="destino1" v-model="form.destino_id" class="custom-control-input" />
                          <label class="custom-control-label" for="destino1">Sitio Destino</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre" class="col-md-5">Nombre</label>
                    <input type="text" id="nombre" v-model="filtros.nombre" class="col-md-7 form-control form-control-sm" />
                  </div>
                  <div class="form-group row">
                    <label for="cod_dane" class="col-md-5">Codigo</label>
                    <input type="text" id="cod_dane" v-model="filtros.codigo" class="col-md-7 form-control form-control-sm" />
                  </div>
                  <div class="form-group row">
                    <label for="sitio_origen_id" class="col-md-5">Origen</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.origen_id"
                      placeholder="Sitios Origen"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.origenes"
                      :filterable="true"
                      @input="buscarOrigenesForm()"
                      :reduce="(sitio) => sitio.id"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="sitio_origen_id" class="col-md-5">Destino</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="filtros.destino_id"
                      placeholder="Sitios Origen"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.destinos"
                      :filterable="true"
                      @input="buscarDestinos()"
                      :reduce="(sitio) => sitio.id"
                    ></v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-sm bg-navy"
              @click="generarListadoExcel()"
              v-if="$store.getters.can('admin.ciudades.export')"
            >
              Generar Listado <i class="fas fa-file-download"></i>
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";

export default {
  name: "CiudadExport",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {
        nombre: true,
        codigo: true,
        origen_id: true,
        destino_id: true,
      },
      filtros: {
        nombre: null,
        codigo: null,
        origen_id: null,
        destino_id: null,
      },
      listasForms: {
        origenes: [],
        destinos: [],
      },
    };
  },
  methods: {
    buscarOrigenesForm() {
      axios
        .get("/api/admin/sitios/lista")
        .then((response) => {
          this.listasForms.origenes = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    buscarDestinos() {
        axios
          .get("api/admin/destinosEcopetrol/lista?sitio=")
          .then((response) =>{
           this.listasForms.destinos = response.data;
          })
          .catch((error) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      },
    
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/rutasEcopetrol/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          location.href = response.data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },
  async mounted() {
    await this.buscarOrigenesForm();
    await this.buscarDestinos();
  },
};
</script>
